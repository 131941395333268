import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: 'YY伙伴-登录'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'YY伙伴-首页'
    }
  },
  {
    path: '/deptStock',
    name: 'deptStock',
    component: () => import('../views/stockModule/deptStock/index.vue'),
    meta: {
      title: '开始盘点'
    }
  },
  {
    path: '/stockRecord',
    name: 'stockRecord',
    component: () => import('../views/stockModule/stockRecord/index.vue'),
    meta: {
      title: '盘点记录'
    }
  },
  {
    path: '/deptCollectMoney',
    name: 'deptCollectMoney',
    component: () => import('../views/deptCollectMoney/index.vue'),
    meta: {
      title: '收银台',
      keepAlive: true
    }
  },
  {
    path: '/salesRecord',
    name: 'salesRecord',
    component: () => import('../views/salesRecord/index.vue'),
    meta: {
      key: 'salesRecord',
      title: '销售记录',
      keepAlive: true
    }
  },
  {
    path: '/customerOrder',
    name: 'customerOrder',
    component: () => import('../views/kedingModule/pages/index.vue'),
    meta: {
      title: '客订记录'
    }
  },
  {
    path: '/InitiateWorkOrder',
    name: 'InitiateWorkOrder',
    component: () => import('../views/InitiateWorkOrder/index.vue'),
    meta: {
      title: '发起工单'
    }
  },
  {
    path: '/deptArrange',
    name: 'deptArrange',
    component: () => import('../views/deptArrange/index.vue'),
    meta: {
      title: '店铺排班'
    }
  },
  {
    path: '/invoicesCenter',
    name: 'invoicesCenter',
    component: () => import('../views/invoicesCenter/index.vue'),
    meta: {
      title: '单据中心'
    }
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import('../views/attendance/index.vue'),
    meta: {
      title: '考勤管理'
    }
  },
  {
    path: '/performanceCalendar',
    name: 'performanceCalendar',
    component: () => import('../views/sales/performanceCalendar/index.vue'),
    meta: {
      title: '业绩日历'
    }
  },
  {
    path: '/timeQuantum',
    name: 'timeQuantum',
    component: () => import('../views/sales/timeQuantum/index.vue'),
    meta: {
      title: '时间段业绩'
    }
  },
  {
    path: '/timeTargetFollow',
    name: 'timeTargetFollow',
    component: () => import('../views/sales/timeTargetFollow/index.vue'),
    meta: {
      title: '时段目标跟进'
    }
  },
  {
    path: '/storeReplenishment',
    name: 'storeReplenishment',
    component: () => import('../views/storeReplenishment/index.vue'),
    meta: {
      title: '店铺补货申请'
    }
  },
  {
    path: '/priceAdjustmentNotice',
    name: 'priceAdjustmentNotice',
    component: () => import('../views/priceAdjustmentNotice/index.vue'),
    meta: {
      title: '调价通知'
    }
  },

  {
    path: '/musicPlayer',
    name: 'musicPlayer',
    component: () => import('../views/other/musicPlayer/index.vue'),
    meta: {
      title: '音乐库'
    }
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/other/message/index.vue'),
    meta: {
      title: '总部通知'
    }
  },
  {
    path: '/openReLabel',
    name: 'openReLabel',
    component: () => import('../views/other/reLabel/index.vue'),
    meta: {
      title: '补打标签'
    }
  },
  {
    path: '/categoryInventory',
    name: 'categoryInventory',
    component: () => import('../views/other/categoryInventory/index.vue'),
    meta: {
      title: '品类库存'
    }
  },
  {
    path: '/expressDelivery',
    name: 'expressDelivery',
    component: () => import('../views/expressDelivery/index.vue'),
    meta: {
      title: '寄快递'
    }
  },
  {
    path: '/employeeManage',
    name: 'employeeManage',
    component: () => import('../views/employeeManage/index.vue'),
    meta: {
      title: '店员管理'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
