import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'

import '@/styles/index.scss'
import '@/assets/styles/global.css'
import '@/assets/iconfonts/iconfont.css'
import '@/assets/new-iconfont/iconfont.js'
import '@/assets/new-iconfont/iconfont.css'

import Cache from './libs/common/cache'
Vue.prototype.$cache = Cache

import Amount from './libs/common/amount'
Vue.prototype.$amount = Amount

import common from './libs/common'
Vue.prototype.$common = common

import Http from './libs/http'
Vue.prototype.$http = Http

import basicButton from '@/components/basicButton'
Vue.component('basic-button', basicButton)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import importDirective from './directive/index.js'
importDirective(Vue)

import messageDialog from '@/components/messageDialog/index.js'
Vue.use(messageDialog)

import './libs/gridmanager/index.css'
import GridManager from './libs/gridmanager/vue2'
Vue.use(GridManager)

var vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue
